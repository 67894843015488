import React from 'react'

import {Avatar, Button, Layout, Result, Typography  } from 'antd'
import { UserOutlined } from '@ant-design/icons'

import logo from '../assets/logo_white.png'

const { Header, Content, Footer } = Layout
const { Link, Title } = Typography


const PermanentlyMoved = () => {

  return (
    <Layout className="layout">
      <Header>
        <div className="header-container">
          <div className="logo"><Link href="https://rewireproject.eu/" target="_blank"><img src={logo} alt="rewire_logo"/></Link></div>

          <div className="loginIcon">
            <Avatar className="userIcon" icon={<UserOutlined />} />
          </div>
        </div>
      </Header>

      <Content>
        <div className={"site-layout-content"}>
          <span><Title className="pageTitle">Cybersecurity Job Ads Analyzer</Title></span>
          <div className=" vertCenter">
            <Result
              title="This application is now integrated into the new CyberABILITY Plarform."
              extra={
                <Link href="https://cyberability-platform.informacni-bezpecnost.cz/">
                  <Button type="primary">Enter the new platform</Button>
                </Link>
              }
            />
          </div>
        </div>
      </Content>

      <Footer style={{ textAlign: 'center' }}>
        REWIRE ©{new Date().getFullYear()}
      </Footer>
  </Layout>


  )
}

export default PermanentlyMoved
